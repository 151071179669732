@import "common";


.ant-slider-handle {
  height: 20px;
  width: 20px;
  margin-top: -8px;
}

.ant-modal {
  top: 50px;

  @include mobile {
    top: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
  }
}

.ant-message-notice-content {
  padding: 16px 16px;
  background: #4353ff !important;
  color: white;

  svg {
    margin-top: -7px;
  }
}

.ant-modal.sticky-footer {
  .ant-modal-footer {
    position: sticky;
    background: white;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
  }
}

.ant-select-item-option {
  height: 50px;
}

.ant-select-item-option-content {
  font-size: 18px;
  padding: 3px 0;

}

.ant-form-item {
  margin-bottom: 0;
}

.row-wrapper {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.flex-start {
    align-items: flex-start;
  }
}

.ant-drawer {
  z-index: 10;
}

.ant-drawer-body {
  a:not(:first-of-type) {
    margin-left: 2px;
  }
}

#launcher-icon-container {
  @include mobile {
    bottom: 70px !important;
  }
}

//* ANTD DROPDOWN  *//

.ant-dropdown-trigger {
  font-weight: 500;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-weight: 500;
  min-width: 180px;
  line-height: 3em;
  padding: 0 1em;
}

.ant-tooltip-inner {
  background-color: #323338;
}

//* ANTD POPOVER  *//

.ant-popover-inner-content {
  max-width: 400px;
  margin: 0;

  a {
    display: inline-block;
    margin: 0px 2px;
  }
}

//* ANTD PAGINATION  *//

.ant-pagination {
  font-weight: 600;

  .ant-pagination-options .ant-select-selector {
    border-radius: 5px;
    overflow: hidden;
    border-color: $PALETTE_PRIMARY;
  }

  li:not(.ant-pagination-options) {
    min-width: 40px;
    height: 40px;
    line-height: 38px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: transparent;
    border-color: $PALETTE_PRIMARY;
    color: $PALETTE_PRIMARY;
    transition: background 0.5s, color 0.5s;

    &:hover {
      a {
        color: white;
      }

      background-color: $PALETTE_PRIMARY;
    }
  }

  .ant-pagination-item {

    &:active,
    &:focus {
      a {
        color: initial;
      }
    }

    &:not(.ant-pagination-item-active) {
      border: none;
    }
  }

  .ant-pagination-item-active {
    a {
      color: $PALETTE_PRIMARY;
    }
  }

  .ant-pagination-next button,
  .ant-pagination-prev button {
    font-weight: bold;
    border: none;
    background-color: transparent;

    &:hover {
      span {
        color: white;
      }
    }
  }

  li.ant-pagination-disabled {
    border-color: transparent;

    &:hover {
      border-color: transparent;
      background-color: transparent;

      span {
        color: initial;
      }
    }
  }
}

.zorba-input-number-item {
  .input-number {
    height: 38px;
  }
}

//* ANT PHOTOS UPLOADER *//

.pictures-wrapper {

  .ant-form-item {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      padding-bottom: 8px;
    }
  }

  margin: 30px 0 0;
  width: 100%;

  .ant-upload-list {
    width: 100%;
  }

  .ant-col.ant-form-item-control {
    width: 100%;
  }

  .ant-upload-list-picture-card {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    border-radius: 3px;
    order: -1;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;

    @include mobile {
      padding: 0;
    }

    .ant-upload-drag-icon {
      margin-top: 10px;
    }

    .ant-upload {
      border-radius: 3px;
      width: 100%;
      max-width: 100%;
      display: inline-block;

      @include mobile {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.ant-select-auto-complete {
  .ant-select-selector {
    .ant-input-affix-wrapper {
      height: 60px;
      max-height: 60px;
    }

    .ant-select-selection-placeholder {
      line-height: 55px;
      border-color: transparent;
      font-size: 16px;
    }
  }

}

.ant-tabs-nav-list {

  .ant-tabs-tab {
    width: 100%;
    color: white;

    span {
      font-size: 16px;
    }

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $PALETTE_PRIMARY;
      }

      span {
        color: white;
      }
    }

  }

  .ant-tabs-ink-bar {
    height: 7px !important;
    background-color: $PALETTE_PRIMARY;
  }
}


.ant-tabs-nav::before {
  border-bottom-width: 7px !important;

}

.ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-tab-btn {
  margin: 0 auto;
}

.ant-slider {
  width: 80%;
  margin: 0 auto;

  .ant-slider-step {
    background: white;
  }

  .ant-slider-handle {
    border-radius: 2px;
    transition: height 0.05s ease;
    transition: width 0.05s ease;

    &:hover {
      margin-top: -9px;
      height: 22px;
      width: 22px;
    }
  }
}

.ant-form-item-control {
  width: 100%;
}

.ant-collapse.zorba-collapse {
  background: transparent;
  border-right: none;
  border-left: none;

  >.ant-collapse-item {
    >.ant-collapse-header {


      font-size: 18px;
      background: transparent;

      @include mobile {
        font-size: 16px;
      }

      @include desktop {
        height: 75px;
        line-height: 45px;
      }
    }

    >.ant-collapse-content {
      border-top: none;
      padding-left: 25px;
      background: transparent;

      .ant-collapse-content-box {
        padding-top: 0;
        font-size: 15px;
      }
    }
  }
}

.consent-step-checkbox {
  align-items: center !important;

  .ant-checkbox+span {
    padding-top: 7px;
    padding-left: 18px;
  }

  .ant-checkbox-wrapper {
    align-items: center;

  }

  .ant-checkbox-checked {
    .ant-checkbox-inner::after {
      left: 34%;
      width: 7.714286px;
      height: 13.142857px;
    }
  }

  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
  }
}

// SELLER WIZARD
.listing-image-uploader {
  width: initial !important;

  .ant-upload-list {
    flex-wrap: wrap;
    display: flex;
    overflow-x: scroll;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 328px;
    height: 218px;

    @include mobile {
      width: 50px;
      height: 50px;
    }

    .upload-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: rgb(40, 40, 40);
      font-size: 16px;
    }
  }

  .ant-upload-list-picture-card-container {
    width: 328px;
    height: 218px;
  }
}

.ant-drawer-title {
  font-size: 24px;
  height: 60px;
  font-weight: 100;
  line-height: 57px;
}

.ant-progress-bg {
  background-color: $PALETTE_PRIMARY  !important;
}

.ant-select-dropdown {
  padding: 0;
  margin: 0;

  @include mobile {
    max-width: 93%;
    min-width: 93% !important;
    width: 100%;
  }
  @include desktop {
    min-width: 450px !important;
  }

  .ant-select-item-option {
    padding: 0;

    >div {
      -webkit-text-size-adjust: none;
      -webkit-font-smoothing: antialiased;
      font: 400 1.6rem/1.3 "Lato", "Helvetica Neue", Arial, sans-serif;
      line-height: 1.3;
      list-style: none;
      list-style-image: none;
      box-sizing: border-box;
      margin: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: 0 0;
      -webkit-tap-highlight-color: transparent;
      padding: 15px;
      cursor: pointer;
      border-bottom: 1px solid #DADADA;
      background-color: #F7F7F7;
      color: #4A4A4A;
      height: 51px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: background-color 0.3s, color 0.3s;
      &:hover {
        -webkit-text-size-adjust: none;
        -webkit-font-smoothing: antialiased;
        font: 400 1.6rem/1.3 "Lato", "Helvetica Neue", Arial, sans-serif;
        line-height: 1.3;
        list-style: none;
        list-style-image: none;
        box-sizing: border-box;
        margin: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        -webkit-tap-highlight-color: transparent;
        padding: 15px;
        cursor: pointer;
        border-bottom: 1px solid #DADADA;
        color: #4A4A4A;
        height: 51px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: background-color 0.3s, color 0.3s;
        background: #dbdbdb;
      }
      >div>div {
        -webkit-text-size-adjust: none;
        -webkit-font-smoothing: antialiased;
        font: 400 1.6rem/1.3 "Lato", "Helvetica Neue", Arial, sans-serif;
        line-height: 1.3;
        list-style: none;
        list-style-image: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        color: #4A4A4A;
        white-space: nowrap;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        font-size: 18px;
        vertical-align: baseline;
        background: 0 0;
      }
    }
  }
}

#upload-wizard {
  padding-bottom: 150px;

  .ant-upload-picture-card-wrapper {
    width: auto;
    display: initial;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .upload-wizard-upload-btn {
    padding: 0px;
    margin: 0;
    margin-right: 10px;

    &.loading {
      visibility: hidden;
    }

    .ant-upload-select-picture-card {
      width: auto;
      height: auto;
      margin: 0 0 7px;
      padding: 0;

      height: calc(43vw);
      width: calc(43vw);
      // box-shadow: 0px 1px 6px -4px rgb(0 0 0 / 46%);
      background: rgba(255, 255, 255, .1);
      border: 2px dotted #cbcbcb;

      @include desktop {
        width: 250px;
        min-width: 250px;
        height: 218px;
        margin-bottom: 20px;
      }
    }

    .upload-btn {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #181818;

      svg {
        margin-bottom: 5px;
        font-size: 30px;
        color: #616364;
      }
    }

  }
}

#photo-upload-wizard {
  .ant-drawer-close svg {
    font-size: 23px;
  }
}

#photo-section {
  .anticon-check-circle {
    font-size: 34px;
  }
}

.ant-input-number {
  &:hover {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
}


h1 {
  -webkit-text-size-adjust: 100%;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  margin: .67em 0;
  max-width: none;
  color: #353760;
  font-size: 47px;
  line-height: 57px;
  font-weight: 600;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 20px;
  @include mobile {
    font-size: 32px;
    line-height: 42px;
  }
}

h2 {
  -webkit-text-size-adjust: 100%;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  color: #353760;
  font-size: 48px;
  line-height: 58px;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: 600;
  background-attachment: scroll !important;
  text-align: center;

}

h3 {
  -webkit-text-size-adjust: 100%;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  text-align: center;
  color: #353760;
  font-size: 40px;
  line-height: 50px;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: 600;
  margin-right: auto;
  margin-left: auto;

  @include mobile {
    font-size: 28px;
  }
  @include desktop {
    max-width: 80%;
  }
}

h4 {
  -webkit-text-size-adjust: 100%;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  text-align: center;
  color: #353760;
  font-size: 30px;
  line-height: 50px;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: 600;
  margin-right: auto;
  margin-left: auto;

  @include mobile {
    font-size: 28px;
  }
  @include desktop {
    max-width: 80%;
  }
}

h5 {
  -webkit-text-size-adjust: 100%;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  margin: .67em 0;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.3px;
  color: #353760;
}

p {
  -webkit-text-size-adjust: 100%;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  margin-top: 0;
  
  color: #333739;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  text-align: left;
  @include mobile {
    font-size: 14px;
    line-height: 24px;  
  }
}