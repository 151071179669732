// @import "colors";
@import 'common';

html,
body {
  height: 100%;
  background: white;
  // font-family: "Poppins", sans-serif !important;
  // font-family: 'Merriweather', serif !important;
  // font-family: 'Open Sans', sans-serif !important;
  font-family: "Modern Era", Roboto, system, -apple-system, BlinkMacSystemFont, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.header-link-active {
  font-weight: 600 !important;
}

.ant-select-selector {
  width: 100%;

  input {
    font-size: 16px;
  }
}

.ant-select-item {
  &.ant-select-item-option-active {
    background-color: transparent;
    color: #f2f2f2;
  }
  &.ant-select-item-option-selected {
    .ant-select-item-option-content {
      background-color: transparent;
      color: #333;
    }
  }

  > .ant-select-item-option-content {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    max-width: 100%;
    font-size: 14px;
    line-height: 29px;
    cursor: pointer;
    transition: background 0.3s ease;

    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #fff;
    color: #333;
    &.active {
      background-color: transparent;
      color: #333;
    }
    .auto-complete-value-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .auto-complete-value-left {
        flex: 1 0 70%;
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: row;
        .place-title {
          font-size: 16px;
        }
        .place-subtitle {
          font-size: 14px;
          font-weight: 100;
          color: #808080;
          padding-left: 5px;
        }
      }
      .auto-complete-value-right {
        flex: 1 0 30%;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        color: #333;
        text-align: right;
        color: #808080;
      }
    }
    .ant-avatar-image {
      margin-right: 10px;
    }
  }
  .ant-select-item-option-content {
    > div > div {
      @include mobile {
        font-size: 14px !important;
      }
    }    
  }
  &:hover {
    background-color: transparent;
    .ant-select-item-option-content {
      background-color: transparent;
      color: #65c0cf;

    }
  }
}
.ant-radio-group {
  .ant-radio-wrapper {
    align-items: center;
    width: 220px;
    height: 70px;
    background: #f7f7f7;
    padding: 10px 10px 10px 20px;
    margin-bottom: 10px;
    flex-basis: 30%;
    width: 30%;
    @include mobile {
      width: 100%;
      flex-basis: initial;
      height: 55px;
    }
  }
  .ant-radio-inner {
    width: 26px;
    height: 26px;

    &::after {
      width: 32px;
      height: 32px;
      border-radius: 60px;
      top: 4px;
      left: 4px;
      background: $PALETTE_PRIMARY;
    }
  }
  span:last-child {
    font-size: 16px;
    padding: 6px 0 0 20px;
  }
}

.ant-input-number {
  width: 100%;
}
.ant-input-number-focused {
  border-color: #333 !important;
  box-shadow: none;
}

.ant-tabs-tab {
  background: rgb(230, 225, 239) !important;
  height: 70px;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  &.ant-tabs-tab-active {
    background: white !important;
  }
  div {
    user-select: none;
  }
}

.calendly-inline-widget {
  height: 100% !important;
}
