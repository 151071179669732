@import "common";

.zorba-radio-group {
  @include mobile {
    width: 100%;
  }
  > div {
    @include mobile {
      flex-direction: column;
      display: flex;
      width: 100%;
    }
  }
  &.direction-column > div {
    flex-direction: column;
    display: flex;
    label {
      border-radius: 2px;
      margin-right: 0;
      @include mobile {
        width: 100%;
        min-height: 60px;
      }
      @include desktop {
        max-width: 400px;
        width: 400px;
        min-height: 70px;
      }
    }
  }
}
