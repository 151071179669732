@import 'common';

.seller-lead-wizard {
  padding-top: 0 !important;
  .retry-btn {
    position: fixed;
    right: 20px;
    top: 25px;
    z-index: 10;
    font-size: 23px;
    cursor: pointer;

    @include mobile {
      font-size: 20px;
      position: fixed;
    }

    &:hover {
      @include desktop {
        color: #8d8d8d;
      }
    }
  }

  >.page-inner {
    @include desktop {
      max-width: 100% !important;
      padding-top: 0 !important;
    }
    

    >.page-inner-content {
      @include mobile {
        padding: 0;
        margin-bottom: 300px;
        
      }

      @include desktop {
        max-width: 100%;
      }
    }

  }

  .spinner-wrapper {
    margin-top: 100px;
  }

  .step-view {
    min-height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    @include mobile {
      min-height: calc(100vh - 150px);
    }

    .step-view-inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mobile {
        width: 100%;
        padding: 0 18px;
      }


      .step-view-component {
        border-radius: 5px;
        padding: 60px 0 0;

        @include mobile {
          width: 100%;
          padding: 12px 0 0;
        }

        @include desktop {
          min-height: 500px;
          width: 1100px;
          // padding: 20px 50px;
          // margin-bottom: 25px;
        }

        .agent-img-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 88px;
          margin: -65px auto 0;
          z-index: 10;
          width: 80px;
          height: 80px;
          position: absolute;
          left: 50%;
          margin-left: -40px;
          bottom: -40px;
          top: 100px;
          // transition: bottom 0.2s ease-in-out, transform 0.2s ease-in-out;
          transition: top 0.2s ease-in-out, transform 0.2s ease-in-out;
          position: fixed;

          @include mobile {
            // top: 100px;
            transform: scale(0.7);
            // position: absolute;
            position: fixed;
            top: 63px;
          }

          &.compact {
            @include desktop {
              top: 63px;
              transform: scale(0.7);
            }
          }

          img {
            width: 100%;
            height: 100%;
            z-index: 10;
            border-radius: 100px;
          }
        }

        .step-header {
          @include desktop {
            margin-top: 30px;
            margin-bottom: 48px;
          }
          .pre-header {
            text-align: center;
            font-size: 24px;
          }

          h1 {
            font-size: 33px;
            font-weight: 300;
            font-family: 'Merriweather', serif;
            text-align: center;
            color: #363939;
            text-size-adjust: 100%;
            font-family: "Modern Era", Roboto, system, -apple-system, BlinkMacSystemFont, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-align: center;
            box-sizing: border-box;
            color: rgb(28, 33, 31);
            font-size: 3rem;
            font-weight: bold;
            margin-left: auto;
            margin-right: auto;
            letter-spacing: -0.02em;
            max-width: 760px;
            margin-bottom: 24px;
            line-height: 55px;

            @include mobile {
              font-size: 32px;
            }
          }

          .desc {
            text-align: center;
            text-size-adjust: 100%;
            font-family: "Modern Era", Roboto, system, -apple-system, BlinkMacSystemFont, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
            color: rgb(28, 33, 31);
            text-align: center;
            box-sizing: border-box;
            font-size: 1.375rem;
            font-weight: 500;
            max-width: 580px;
            margin-left: auto;
            margin-right: auto;
            line-height: 33px;

            @include mobile {
              padding: 0 0 10px;
              font-size: 100%;
            }

            @include desktop {
              padding: 0 0 35px;
            }
          }
        }

        .step {
          display: flex;
          align-items: center;
          justify-content: center;

          &.full-name {
            input {
              max-width: 200px;
              width: 100%;
              margin: 0 10px;
            }
          }

          &.property-fill {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .row {
              display: flex;
              width: 100%;
              justify-content: center;

              &.address-fields {
                @include mobile {
                  flex-direction: column;
                }
              }
            }

            .places-auto-complete {
              width: 100%;
              max-width: 450px;
              margin: 0;

              @include mobile {
                max-width: 100%;
              }

              >div {
                margin: 0;
                height: 50px;
              }

              input {
                height: 50px;
                font-size: 16px;
                border-radius: 8px;
                padding: 4px 11px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 1.5715;
                background-color: #fff;
                background-image: none;
                border: 1px solid #d9d9d9;
                transition: all 0.3s;
                width: 100%;

                &:focus {
                  border-color: #333;
                  box-shadow: 0 0 0 1px #333 inset;
                }
              }
            }

            .zorba-input {
              margin: 0;

              @include mobile {
                margin-top: 15px;
              }

              @include desktop {
                margin: 0 0 0 20px;
                max-width: 150px;
              }
            }

            .map-preview {
              overflow: hidden;
              display: none;
              max-height: 0;
              height: 0;
              display: flex;
              height: 100%;
              transition: max-height 0.5s ease-in-out;
              margin-bottom: 25px;
              @include mobile {
                width: 100%;
              }

              &.open {
                max-height: 160px;
                display: block;
              }
            }
          }

          &.property-verification {
            flex-direction: column;

            .map-wrapper {
              height: 300px;
              width: 100%;
              margin-bottom: 20px;
              min-height: 300px;
              cursor: url(https://maps.gstatic.com/mapfiles/openhand_8_8.cur),
                default;

              @include mobile {
                height: 200px;
                min-height: 200px;
              }

              .marker {
                // width: 50px;
                // height: 50px;
              }
            }

            .address-text {
              font-size: 18px;
              font-weight: 600;
            }
          }

          &.asking-price {
            display: flex;
            flex-direction: column;

            .inner {
              width: 100%;
              max-width: 500px;
              display: flex;
              justify-content: center;

              .zorba-input {
                max-width: 250px;
              }

              input {
                width: 100%;
              }
            }
          }

          &.contact-details {
            flex-direction: column;

            .inner {
              max-width: 400px;

              input {
                margin: 0 auto 25px;
              }
            }
          }

          &.loading {
            >.inner {
              width: 100%;
              max-width: 660px;
              margin: 0 auto;

              .spinner-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile {
                  justify-content: flex-start;
                }

                .spinner-text {
                  font-size: 16px;
                }

                img {
                  width: 45px;
                }
              }

              .features {
                margin-top: 35px;

                .feature {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 5px;

                  .title {
                    font-size: 15px;
                    color: #363939;
                  }
                }
              }
            }
          }

          .bubble-wrapper {
            display: flex;
            align-items: flex-end;
            margin-top: 25px;

            img {
              width: 45px;
              height: 45px;
              border-radius: 45px;
              margin-bottom: 5px;
            }

            .note {
              background: #f7f7f7;
              border-radius: 15px;
              padding: 18px 15px;
              margin-left: 10px;
              margin-top: 30px;
              font-size: 1.313rem;
            }
          }

          &.hoa {
            display: flex;
            flex-direction: column;
            .hoa-options {
              margin-top: 20px;
              max-width: 420px;
              width: 420px;
              margin: 0 auto;
              // background: #f7f7f7;
              padding: 15px;
              @include mobile {
                width: calc(99vw - 20px);
              }
              .hoa-question {
                margin-bottom: 15px;
              }
              ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                > li {
                  padding: 5px 0;
                  // font-weight: 500;
                  label {
                    font-size: 16px;
                  }
                }
              }
              h3 {
                padding: 0 0 10px;
                margin: 0;
                --shadows-small: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
                text-rendering: optimizelegibility;
                font: 400 16px / 1.7 Graphik, sans-serif;
                line-height: 1.3;
                -webkit-font-smoothing: antialiased;
                font-family: Graphik, system-ui, sans-serif;
                font-weight: var(--fontWeights-semibold);
                font-weight: bold;
                color: var(--colors-neutrals100);
                font-size: 16px;
                margin-top: 10px;                
              }
            }
            .hoa-fee-container {
              margin-bottom: 10px;
            }
          }

          .note {

            border-radius: 15px;
            padding: 18px 15px;
            margin-left: 10px;
            font-size: 21px;

            @include mobile {
              font-size: 100%;
            }

            @include desktop {
              padding: 35px 15px 0;
            }
            
            > ul{
              margin: 0;
              padding-left: 0;
            }
          }
        }
      }

      .footer-buttons {
        bottom: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;

        @include mobile {
          position: initial;
          position: fixed;
          padding: 0;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          height: 88px;
        }
        @include desktop {
          right: 25px;
        }
        .mobile-bg {
          -webkit-mask-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(0, 0, 0, 0.8)), to(rgb(0, 0, 0)));          
          background: white;
          position: fixed;
          z-index: 10000;
          width: 100%;
          left: 10;
          height: 88px;
          height: 100%;
          content: "";
          width: 500px;
          @include desktop {
            display: none;
          }
        }

        >.item {
          @include desktop {
            position: fixed;
            right: 0;
            bottom: 0;
          }
          >button {
            height: 65px;
            padding: 14px 25px 18px;
            text-size-adjust: 100%;
            box-sizing: border-box;
            outline: none;
            font-family: inherit;
            overflow: visible;
            text-transform: none;
            appearance: button;
            transition: color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
            display: inline-block;
            border: none;
            border-radius: 100px;
            font-weight: bold;
            text-align: center;
            user-select: none;
            vertical-align: middle;
            font-size: 1.125rem;
            background: $PALETTE_PRIMARY;
            color: rgb(255, 255, 255);
            line-height: 1.45;
            z-index: 99999;
            cursor: pointer;
            
            left: unset;
            bottom: 20px;

            gap: 10px;
            margin: 0px 24px 0px 16px;
            transform: none;
            min-width: unset;
            text-align: center;
            min-width: 130px;
            @include mobile {
              position: fixed;
              bottom: 25px;
              right: 5px;
              height: 60px;
              bottom: 15px;
            }
            @include desktop {
              right: 16px;
            }

            &:hover {
              background: #657aca;
            }

            &:disabled {
              text-size-adjust: 100%;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              -webkit-font-smoothing: antialiased;
              box-sizing: border-box;
              outline: none;
              font-family: inherit;
              overflow: visible;
              text-transform: none;
              appearance: button;
              transition: color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
              display: inline-block;
              border: none;
              border-radius: 100px;
              font-weight: bold;
              text-align: center;
              user-select: none;
              vertical-align: middle;
              font-size: 1.125rem;
              background: rgb(73, 77, 76);
              line-height: 1.45;
              z-index: 99999;
              left: unset;
              gap: 10px;
              transform: none;
              min-width: unset;
              background-color: rgb(195, 198, 203);
              opacity: 1;
            }

            >span {
              text-size-adjust: 100%;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              -webkit-font-smoothing: antialiased;
              font-family: inherit;
              text-transform: none;
              font-weight: bold;
              text-align: center;
              user-select: none;
              font-size: 1.125rem;
              line-height: 1.45;

              box-sizing: border-box;
              position: relative;
              z-index: 2;
              color: rgb(255, 255, 255);
            }
          }
        }

        .next-btn {
          padding: 0 45px;

          max-width: 200px;
          min-width: 200px;
          margin: auto;
          line-height: 50px;

          @include mobile {
            max-width: 100%;
            min-width: 100%;
          }

          span {
            font-size: 16px;
          }
        }


        .disclaimer {
          color: #989c9e;
          padding-top: 70px;
          max-width: 700px;
          margin: 0 auto;
          @include mobile {
            padding-top: 10px;
          }
        }
      }
    }

    .desktop-back-btn {
      cursor: pointer;
      position: fixed;
      left: 100px;
      top: 170px;
      font-size: 45px;

      @include mobile {
        display: none;
      }
    }
  }
}

.phone-input-error {
  font-size: 12px;
  color: $PALETTE_RED;
}