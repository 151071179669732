@import "colors";

.auto-complete-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  > .ant-form-item-label {
    text-align: left;
    > label {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      &::after {
        display: none;
      }
    }
  }
  > .ant-form-item-control {
    width: 100%;
    max-width: 450px;
    > .ant-form-item-explain {
      padding-left: 3px;
    }
  }
}
.auto-complete {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  margin: 0 0 4px 0;
  height: 38px;
  i {
    color: #808080;
  }
  .fa-check-circle {
    color: $PALETTE_GREEN;
  }

  &.has-prefix {
    > .ant-select-selector > .ant-select-selection-placeholder {
      padding-left: 30px;
      padding-top: 2px;
    }
  }

  > .ant-select-selector {
    width: 100%;
    > .ant-select-selection-search {
      height: 38px;
      > .ant-input-affix-wrapper {
        height: 38px;
        line-height: 38px;
        padding: 0 11px;
        &:focus,
        &:hover {
          z-index: initial;
        }
      }
      input {
        height: 38px;
        max-height: 34px;
        font-size: 16px;
      }
    }
    
  }

  .spinner-wrapper {
    position: absolute;
    top: 7px;
    right: 5px;
  }

  .ant-select-arrow-loading {
    user-select: none;
    margin-right: 25px;
    font-size: 19px;
    margin-top: -10px;
    color: #333;
  }
}
