@import 'common';

.header-links {
  @include mobile {
    display: flex;
    flex-direction: column;
  }
  span {
    @include mobile {
      margin-bottom: 10px;
    }
  }
  a {
    padding: 0 10px;
    color: #404a4f;
    font-size: 1rem;
    text-decoration: none;
    padding: 0.5em 1.2em;
  }
}
