@import "common";

.login-buttons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
.header-login-btn > span {
  // text-decoration: underline;
  color: #333 !important;
  border-bottom: 1px solid #333;
  padding-bottom: 0;
  font-weight: 400;
  max-height: 29px;
  font-size: 16px;
}
.header-signup-btn {
  display: none;
  @include mobile {
    
  }
  .zorba-button {
    padding: 4px 5px;
    font-size: 16px;
    border: 1px solid $PALETTE_PRIMARY;
    border-radius: 0;
    &:hover {
      background: white;
      span {
        color: $PALETTE_PRIMARY;
      }
    }
    @include mobile {
      font-size: 14px;
      padding: 0px 0;
      height: 34px;
      margin: 0;
    }
    @include desktop {
      padding: 0 25px;
    }
    span {
      @include mobile {
        padding: 0 10px;
      }
    }
  }
}


.sticky-footer {
  display: flex;
  position: fixed;
  bottom: 0;
  @include desktop {
    display: none;
  }
  .zorba-button {
    padding: 4px 5px;
    font-size: 16px;
    border: 1px solid $PALETTE_PRIMARY;
    border-radius: 0;
    &:hover {
      background: white;
      span {
        color: $PALETTE_PRIMARY;
      }
    }
    @include mobile {
      font-size: 14px;
      padding: 0px 0;
      height: 34px;
      margin: 0;
    }
    @include desktop {
      padding: 0 25px;
    }
    span {
      @include mobile {
        padding: 0 10px;
      }
    }
  }
}