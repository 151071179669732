@import "common";

.zorba-button {
  background-color: $PALETTE_PRIMARY;
  color: $PALETTE_SECONDARY;
  border: none;
  line-height: 37px !important;
  height: 56px;
  border-radius: 3px;
  padding: 0 23px;
  transition: all 0.3s ease 0s;
  border: 1px solid $PALETTE_PRIMARY;
  cursor: pointer;
  @include mobile {
    border-radius: 0;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.3px;
  }
  &:focus {
    background-color: $PALETTE_PRIMARY;
    color: $PALETTE_SECONDARY;
  }
  &.btn-rounded {
    border-radius: 40px;
  }
  span {
    box-shadow: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
  }
  i,
  .fas {
    padding-right: 10px;
  }
  &:active,
  &:hover {
    background-color: $PALETTE_SECONDARY;
    box-shadow: 0px 1px 5px rgba(255, 255, 255, 0.25);
    border-color: $PALETTE_PRIMARY;
    span,
    i {
      color: $PALETTE_PRIMARY;
    }
  }
  &:disabled {
    border: none;
  }
  &:disabled {
    span,
    i {
      
      color: $PALETTE_DARK_GREY;
    }
  }
  &.variant_secondary {
    background-color: $PALETTE_SECONDARY;
    border: 2px solid $PALETTE_PRIMARY;
    color: $PALETTE_PRIMARY;
    padding: 0;
    width: auto;

    i,
    span {
      color: inherit;
    }

    span {
      padding: 0 20px;
    }

    &:active,
    &:hover {
      color: white;
      background: $PALETTE_PRIMARY;
      border-color: $PALETTE_PRIMARY;
    }
    &:disabled {
      background: $PALETTE_DISABLED;
      border-color: $PALETTE_DISABLED_SECONDARY;
      cursor: not-allowed;
      span {
        color: $PALETTE_DARK_GREY;
      }
    }
  }
  &.variant_tertiary {
    background-color: transparent;
    border: 2px solid transparent;
    color: #7c7d7f;
    padding: 0;
    width: auto;
    box-shadow: none;

    &:active,
    &:hover {
      color: black;
      i,
      span {
        color: black;
      }
    }
    &:disabled {
      background-color: transparent;
      border: transparent;
      cursor: not-allowed;
      span {
        color: #7c7d7f;
      }
    }
    i,
    span {
      color: #7c7d7f;
    }
    span {
      padding: 0 6px;
    }
  }
  &.variant_link {
    background-color: transparent;
    border: none;
    color: #f2f2f2;
    &:hover {
      color: #333;
      i,
      span {
        color: #333;
      }
    }
    i,
    span {
      color: #8a8d94;
    }
  }
  &.full-width {
    width: 100%;
  }

  &.variant_success {
    background: #2DC68F;
    border: 2px solid white;

    i,
    span {
      color: inherit;
    }

    span {
      padding: 0 20px;
    }

    &:active,
    &:hover {
      color: #2DC68F;
      background: white;
      border-color: #2DC68F;
    }
    &:disabled {
      background: $PALETTE_DISABLED;
      border-color: $PALETTE_DISABLED_SECONDARY;
      cursor: not-allowed;
      span {
        color: $PALETTE_DARK_GREY;
      }
    }    
  }
  &.variant_dark {
    background-color: $PALETTE_SECONDARY;
    border: 2px solid #0b0a0a;
    color: #0b0a0a;
    padding: 0;
    width: auto;
    border-radius: 5px;

    i,
    span {
      color: inherit;
    }

    span {
      padding: 0 20px;
    }

    &:active,
    &:hover {
      color: white;
      background: #0b0a0a;
      border-color: #0b0a0a;
    }
    &:disabled {
      background: $PALETTE_DISABLED;
      border-color: $PALETTE_DISABLED_SECONDARY;
      cursor: not-allowed;
      span {
        color: $PALETTE_DARK_GREY;
      }
    }
  }
}
