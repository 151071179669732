@import "common";


.ant-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  @include desktop {
    height: calc(100%);
    .layout-content {
      height: 100%;
    }
  }

  @include mobile {
    flex-direction: column;
  }

  &.ant-layout-has-sider {
    @include mobile {
      flex-direction: column;
    }
  }

  .layout-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #fafafa;

    @include mobile {
      // padding-top: 73px;
      // margin-bottom: 60px;
      height: 100%;
      max-height: calc(100vh - 73px);
    }
  }
  .layout-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: white;
    z-index: 2;
  }

  > .hidden {
    visibility: hidden;
  }

  .ant-picker-decade-panel,
  .ant-picker-cell-disabled {
    &::before {
      background: transparent;
    }
    .ant-picker-cell-inner {
      background: transparent;
    }
  }
}
.invalid {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
