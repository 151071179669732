@import "common";

.zorba-input-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 11px;
  width: 100%;
  &.full-width {
    width: 100%;
  }
  &.edit {
    .ant-form-item-label > label {
      font-size: 17px;
      color: #222;
      font-weight: 600;
      margin: 0 15px 0 0;
      padding: 0;
    }
    .ant-form-item-control,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content {
      outline: none;
      border: none;
    }
  }
  .ant-form-item-label > label {
    padding: 0 0 7px;
    margin: 0;
    color: #222;
    font-size: 16px;
    font-weight: 600;
    &::after {
      display: none;
    }
  }

  .ant-picker {
    width: 100%;
  }
  .ant-picker-input > input {
    padding: 3px 0;
  }
}

.zorba-input {
  height: 50px;
  font-size: 16px;  
  border-radius: 8px;
  &:focus {
    border-color: #333;
    box-shadow: 0 0 0 1px #333 inset;    
  }
  &.full-width {
    width: 100%;
  }
  &.clean {
    border: none;
    background: transparent;
    height: 30px;
    font-size: 14px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &.ant-input-number {
    width: 100%;
  }
  &.edit {
    border-width: 0;
    &:hover,
    &:focus {
      border-bottom-width: 1px;
      border-right: 0;
      outline: none;
      box-shadow: none;
    }
    input {
      padding-left: 0;
    }
  }
  i {
    color: #808080;
    &.fa-check-circle {
      color: $PALETTE_GREEN;
    }
  }
  .ant-input-prefix {
    margin-right: 7px;
  }
  .spinner-wrapper {
    padding: 0;
    .anticon {
      font-size: 20px;
      color: #333;
    }
  }
  &.ant-input-affix-wrapper {
    padding: 0 11px;
    &:focus {
    }
    > input {
    height: 47px;
    border-radius: 8px;
    
    &:focus {
    }
    }
  }
  input {
    height: 50px;
    font-size: 16px;
    border-radius: 8px;
    &:focus {
      border-color: $PALETTE_PRIMARY;
      box-shadow: 0 0 0 1px $PALETTE_PRIMARY inset;    
    }
  }
  &.phone-number-input {
    input {
      height: 40px;
    }
  }
  &.input-invalid {
    border-color: $PALETTE_RED;
  }
}

