@import "common";

.layout-header {
  padding: 19px 24px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 3;
  position: fixed;

  @include mobile {
    padding: 13px 12px 13px;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.9);
    height: 73px;
  }
  @include desktop {
  }
  @media print {
    display: none;
  }

  .layout-header-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    .user-info {
      @include mobile {
        display: none;
        margin-top: initial;
      }
    }
  }

  .left,
  .right,
  .center {
    flex: 1 0 20%;
  }
  .left {    
    display: flex;
    justify-content: flex-start;
    @include mobile {
      align-items: center;
      // flex: 1 0 60%;
      flex: 0 0 38%;
    }
    @include desktop {
      flex: 1 0 25%;
    }
    .logo-wrapper {
      padding-right: 15px;
      i {
        font-size: 30px;
      }
    }

    .drawer-menu {
      i {
        font-size: 25px;
        margin-right: 15px;
      }
    }
    .menu-btn {
      background: transparent;
      border: none;
      &.disabled {
        cursor: initial;
      }
      .menu-icon {
        color: $PALETTE_THIRD;
        font-size: 23px;

      }
    }
    .logo-wrapper {
      cursor: pointer;
      height: 35px;
      @include mobile {
        height: 28px;
      }
      @include desktop {
        padding-left: 25px;
      }
      img {
        height: 100%;
      }
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      display: none;
    }
    @include desktop {
      flex: 1 0 50%;
    }
    .mobile-logo {
      width: auto;
      height: 34px;
      
    }
  }

  .right {
    width: 25%;
    flex: 1 0 25%;
    display: flex;
    justify-content: flex-end;
    @include mobile {
      align-items: center;
      flex: 1 0 40%;
      width: initial;
    }
    .phone-btn {
      color: #333;
      font-size: 18px;
      margin-left: 10px;
      margin-right: 24px;
      padding-top: 5px;
      white-space: nowrap;
      @include mobile {
        display: none;
      }
      @include desktop {
        margin-right: 35px;
        
      }
      i {
        padding-right: 7px;
      }

    }
    .login-btn-empty-state {
      height: 38px;
      display: flex;

    }
    .premium-btn {
      margin-right: 10px;
      border-radius: 6px;
      height: 36px;
      @include mobile {
        display: none;
      }
    }
    .user-info img {
      width: 35px;
      height: 35px;
      margin-right: 25px;
      @include mobile {
        margin-right: 10px;
      }
    }
    .create-project-button {
      max-width: 180px;
      font-size: 17px;
      font-weight: 500;
      @include mobile {
        max-width: 150px;
        height: 30px;
        padding: 0;
        width: 30px;
        text-align: center;
        border-radius: 7px;
      }
      &.request-details {
        @include mobile {
          font-size: 13px;
          width: initial;
          padding: 0 5px;
          font-weight: 400;
        }
        i {
          @include mobile {
            display: none;
          }
        }
      }
      i {
        @include mobile {
          padding: 0;
        }
      }
    }
    .inbox-offers {
      @include desktop {
        margin-right: 25px;
      }

      button {
        font-weight: 500;
      }
    }
    .sidebar-btn {
      border: none;
      padding: 0;
      align-self: flex-end;
      margin: 2px 9px;
      @include mobile {
        background: transparent;
      }
      color: inherit;
      &:hover {
        color: rgb(64, 169, 255);
      }

      i {
        color: #5d5d61;
        font-size: 20px;
        line-height: 22px;
      }
    }
    .notification-btn-wrapper {
      margin: 16px 9px 0 0;
      @include mobile {
        margin: 5px 9px 0 0;
      }
      &.notifications {
        @include mobile {
          display: none;
        }
      }
      @include desktop {
        margin: 5px 20px 0 0;
      }
      .notification-btn {
        margin: 0;
      }
    }
  }
}

.header-drawer {
  .mobile-logo {
    width: 140px;
    margin: 20px 0 0;
  }
  padding .anticon-close {
    color: #fff;
  }
  .ant-drawer-body {
    padding: initial;
    background: #fff;
  }
}
